import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Often in our modern culture, we conflate kindness and love. However, kindness,
in it's corrupted form starts to look more like indifference to me. Kindness is
often used to justify sheltering another from growth opportunities. Consider the
prodigal son, his father had to let him eat with the pigs, homeless because he
knew that would be the only medium by which he would "come to himself".`}</p>
    <p>{`Often we let suffering go on indefinitely to ourselves and to the beloved in our
attempts to be kind. We let other principles such as honesty and vulnerability
take a back seat in the name of kindness.`}</p>
    <p>{`I am not suggesting we cite love as a reason for cruelty. We have to consider
how to best help the sufferer with empathy and compassion. After all, love calls
us to share in another's burden. Love calls us to suffer with them,
`}<a parentName="p" {...{
        "href": "/thoughts/gods-love"
      }}>{`see more.`}</a></p>
    <p>{`Kindness must not become the bars between us and a loved one; bars we helped
build with quotes & suggestions meant to comfort ourselves as much as to comfort
them. Imprisoning ourselves watching the beloved suffer, unable to help them
only saying what falsely comforts and ultimately destroys because of "kindness".`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      